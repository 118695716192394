import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import { AppRoute } from "../components/AppRoute";
import { GlobalAudio } from "../components/GlobalAudio";
import ArticlePage from "./ArticlePage";
import { LanguagePage } from "./LanguagePage";
import Page1 from "./Page1";
import Page2 from "./Page2";
import Page3 from "./Page3";
import Page4 from "./Page4";
import { PanelsPage } from "./PanelsPage";
import { languageRoute, page1Route, page2DetailRoute, page2Route, panelsRoute, page4Route } from "./RoutePath";
import { SplashPage } from "./SplashPage";
import AudioArticlePage from "./AudioArticlePage";

console.log(process.env.PUBLIC_URL);
console.log(process.env.NODE_ENV);

export function MainPage() {
    return (
        <BrowserRouter basename={process.env.PUBLIC_URL}>
            <Routes>
                <AppRoute
                    path="/"
                    element={<SplashPage />}
                />
                <AppRoute
                    path={languageRoute}
                    element={<LanguagePage />}
                />
                <AppRoute 
                    path={panelsRoute}
                    element={<PanelsPage/>}
                />
                <AppRoute
                    path={page1Route}
                    element={<Page1 />}
                />
                <AppRoute
                    path={page2Route}
                    element={<Page2 />}
                />
                <AppRoute
                    path={page4Route}
                    element={<Page4 />}
                />
{/*                 <AppRoute 
                    path={page3Route}
                    element={<Page3 />}
                />
                <AppRoute 
                    path={page3DeailRoute}
                    element={<ArticlePage />}
                /> */}
                <AppRoute 
                    path={page2DetailRoute}
                    element={<AudioArticlePage />}
                />
                <Route
                    path="/*"
                    element={<Navigate to="/" />}
                />
            </Routes>
            <GlobalAudio />
        </BrowserRouter>
    );
}
