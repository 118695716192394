import React, { useEffect, useState } from "react";
import styles from "./Page4.module.scss";
import { Header } from "../components/Header";
import { SocialFooter } from "../components/SocialFooter";
import { useStrings } from "../localization/hooks/useStrings";
import CoverSrc from "../assets/images/layout/pagani_vetture.png";
import { TextItem } from "../components/TextItem ";
import { page3Entries } from "../models/TextModel";
import $ from "jquery";

type Props = {
	className?: string;
};

export default function Page4(props: Props) {
	const [stickyTop, setStickyTop] = useState(0);
	const [scrollTarget, setScrollTarget] = useState(false);
	const [timelineFixed, setTimelineFixed] = useState(false);
	const [activeIndex, setActiveIndex] = useState(-1);

	const TIMELINE_VALUES = {
		start: 48,
		step: 30,
	};

	useEffect(() => {
		const timeline = document.querySelector(".timeline__nav");
		const items = document.querySelectorAll(".timeline__nav li");
		const milestones = document.querySelectorAll(
			".timeline__section .milestone"
		);
		const offsetTop = parseInt(
			getComputedStyle(timeline as HTMLElement).top
		);

		const handleResize = () => {
			setTimelineFixed(false);
			setStickyTop((timeline as HTMLElement).offsetTop - offsetTop);
		};

		const handleScroll = () => {
			if (window.pageYOffset > stickyTop) {
				setTimelineFixed(true);
			} else {
				setTimelineFixed(false);
			}

			if (scrollTarget === false) {
				let viewLine = window.pageYOffset + window.innerHeight / 3;
				let active = -1;

				milestones.forEach((milestone, index) => {
					if ((milestone as HTMLElement).offsetTop - viewLine > 0) {
						return false;
					}
					active = index;
				});

				(timeline as HTMLElement).style.top =
					-1 * active * TIMELINE_VALUES.step +
					TIMELINE_VALUES.start +
					"px";

				items.forEach((item) => item.classList.remove("active"));
				items[active !== -1 ? active : 0].classList.add("active");
			}
		};

		window.addEventListener("resize", handleResize);
		window.addEventListener("scroll", handleScroll);

		handleResize();
		handleScroll();

		return () => {
			window.removeEventListener("resize", handleResize);
			window.removeEventListener("scroll", handleScroll);
		};
	}, [scrollTarget, TIMELINE_VALUES]);

	const handleItemClick = (index: any) => {
		const items = document.querySelectorAll(".timeline__nav li");
		const milestone = document.querySelector(
			`#milestone-${index}`
		);

		if (!items[index].classList.contains("active") && milestone) {
			setScrollTarget(index);

			const scrollTargetTop = (milestone as HTMLElement).offsetTop;

			window.scrollTo({
				top: scrollTargetTop,
				behavior: "smooth",
			});

			setScrollTarget(false);
		}
	};

	const items = [
		1993, 1994, 1995, 1996, 1997, 1998, 1999, 2000, 2001, 2002, 2003, 2004,
		2005, 2006, 2007, 2008, 2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016,
		2017, 2018,
	];

    const milestones = [];
    for (let i = 1993; i < 2019; i++) {
        milestones.push(i);
    }

	return (
		<>
			<Header hasMenuButton={true} />
			<div className={`page timeline-container`}>
				<article className="timeline">
					<nav className="timeline__nav">
						<ul>
							{items.map((item, index) => (
								<li
									key={index}
									onClick={() => handleItemClick(index)}
								>
									{item}
								</li>
							))}
						</ul>
					</nav>
					<section className="timeline__section">
						<div className="wrapper">
                            {milestones.map((milestone, index) => (
                                <div key={index} id={"milestone-"+index}>
                                    <h2 className="milestone">{milestone}</h2>
                                    <p> Horacio pagani </p>
                                </div>
                            ))}
						</div>
					</section>
				</article>
			</div>
		</>
	);
}
