export const languageRoute = "/lingua";
export const panelsRoute = "/Navigazione";
export const page1Route = "/Benvenuti";
export const page2Route = "/Museo";
export const page2DetailRoute = "/Museo/Dettaglio";
/* export const page3Route = "/Vetture";
export const page3DeailRoute = "/Vetture/Dettaglio"; */
export const page4Route = "/Timeline";


export type RoutePath = "/"
    | typeof languageRoute
    | typeof page1Route
    | typeof page2Route
    | typeof panelsRoute
    | typeof page2DetailRoute
    | typeof page4Route
/*     | typeof page3Route
    | typeof page3DeailRoute */
    | "/test"
    ;